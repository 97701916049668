
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ImgLarge from "./LargeImg.vue";
@Component({
    name: "BasicInfo",
    components: {
        ImgLarge,
    },
})
export default class BasicInfo extends Vue {
    @Prop(Object) private resData!: object;
    private activeName: string = "first";
    private enlargeFlag: boolean = false;
    private enlargeSrc: string = ""; // 传给子组件的图片url
    private num: string = ""; // 传给子组件的图片类型
    // 表单字段
    private form: any = {};
    private businessLicenseTab: any = {};
    private contactTab: any = {};
    private businessRules: any = {
        photo: [
            { required: true, message: '请上传营业执照副本', trigger: 'change' },
        ],
        name: [
            { required: true, message: '请输入企业名称', trigger: 'change' },
        ],
        // documentType: [
        //     { required: true, message: '证件类型', trigger: 'blur' },
        // ],
        registrationNumber: [
            { required: true, message: '请输入营业执照注册号', trigger: 'change' },
        ],
        // taxpayerNumber: [
        //     { required: true, message: '请输入纳税人识别号', trigger: 'change' },
        // ],
        address: [
            { required: true, message: '请输入详细地址', trigger: 'change' },
        ],
        cityCode: [
            { required: true, message: '营业执照所在地', trigger: 'blur' },
        ],
        industry: [
            { required: true, message: '请选择行业', trigger: 'change' },
        ],
    };
    private contactRules: any = {
        idCardFrontPhoto: [
            { required: true, message: '请上传身份证正面照', trigger: 'change' },
        ],
        idCardBackPhoto: [
            { required: true, message: '请上传身份证背面照', trigger: 'change' },
        ],
        name: [
            { required: true, message: '请输入联系人姓名', trigger: 'blur' },
        ],
        idCardNumber: [
            { required: true, message: '请输入联系人身份证号', trigger: 'blur' },
        ],
    };
    @Watch('resData', {immediate: true, deep: true}) // 监听父组件传过来的值
    private resDataFun(value: number) {
        this.businessLicenseTab = JSON.parse(JSON.stringify(value)).businessLicense;
        this.contactTab = JSON.parse(JSON.stringify(value)).contact;
        this.businessLicenseTab.industry = JSON.parse(JSON.stringify(value)).industryLevelName || '-';
    }
    private created() {
        // ...
    }
    // 图片放大功能
    private previewImg(str: string, num: string) {
        this.enlargeFlag = true;
        this.enlargeSrc = str;
        this.num = num;
    }
    // 监听子组件传回来的弹窗关闭标志
    private propChildImgFun(val: boolean) {
        this.enlargeFlag = val;
    }
}
