
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
@Component({
  name: 'TableTool',
})
export default class TableTool extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Prop(Object) private row!: any;

    private showDialog: boolean = false; // 弹窗显示与关闭
    private title = '重置密码';
    private form = {
        password: '',
    };
    private rules: any = {
        password: [
            { required: true, message: '请输入密码', trigger: 'change' },
            { validator: this.validateOldPwd, trigger: 'change' },
        ],
    };
    private validateOldPwd(rule: any, value: string, callback: any) {
        if (value === '') {
            callback(new Error('请输入密码'));
        } else if (value !== '' && (value.length < 6 || value.length > 30)) {
            callback(new Error('请输入6-30位密码'));
        } else {
            callback();
        }
    }
    @Watch('exitFlag') // 监听父组件传过来的值
    private exitFlagFun(value: boolean) {
        this.showDialog = value;
    }
    // 给父组件传值
    @Emit('tabCloseMsg')
    private closeTodo(num: number): number {
        this.form = {
            password: '',
        };
        const elForm = this.$refs.form! as ElForm;
        elForm.resetFields();
        return num;
    }
    //  确定按钮
    private closeDialog(num: number) {
        if (num === 3) {
            const formData = this.$refs.form! as ElForm;
            formData.validate((valid) => {
                if (valid) {
                    this.$httpService.putData(this.form,
                    '/apiProxy/api/frontend/company/customers/' + this.row.id + '/reset-password')
                    .then((res: any) => {
                        this.showDialog = false;
                        this.closeTodo(num); // 给父组件传值
                        this.$message({
                            message: '重置成功！',
                            type: 'success',
                        });
                    });
                }
            });
        } else {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        }
    }
    // 右上角的关闭按钮
    private beforeclose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
}
