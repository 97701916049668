
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import BaseInfo from "./component/BasicInfo.vue";
import EditInfo from "./component/EditInfo.vue";
import ImgLarge from "./component/LargeImg.vue";
import SuccessInfo from "./component/SuccessInfo.vue";
import ContentTitle from "@/components/ContentTitle.vue";

@Component({
    name: "Certification",
    components: {
        BaseInfo,
        ImgLarge,
        SuccessInfo,
        ContentTitle,
        EditInfo,
    },
})
export default class Certification extends Vue {
    private contentTitle: string = "企业资料";
    // /**未修改**/
    // NON_CHANGE(-2),
    // /**待审核**/
    // PENDING(-1),
    // /**审核不通过**/
    // CHECK_FAIL(0),
    // /**审核成功**/
    // CHECK_SUCCESS(1);
    private changeStatus: number = 0;
    private certStatus: number = 0;
    private resInfo: any = {};
    private resFlag: boolean = false;
    private changeStp: number = 1;
    private examineFalseShow: boolean = false;
    private editFalseShow: boolean = false;
    @Prop({
        type: Boolean,
        default: false,
    }) private isBack!: boolean; // 接收父组件传过来的值

    private created() {
        this.getCertificationInfo();
    }
    private receiveEditInfo(num: number) {
        if (num === 1) {
            this.$router.go(0);
        } else {
            this.getCertificationInfo();
            this.examineFalseShow = false;
            this.editFalseShow = false;
        }
    }
    private getCertificationInfo() {
        this.resFlag = false;
        this.$httpService.getData({}, '/apiProxy/api/frontend/company/info/certification').then((res: any) => {
            this.changeStatus = res.changeStatus;
            this.certStatus = res.certStatus;
            this.resInfo = res;
            this.resFlag = true;
            if (this.certStatus !== 2) {
                switch (this.certStatus) {
                    case 0:
                        this.changeStp = 1;
                        break;
                    case 1:
                        this.changeStp = 2;
                        break;
                    case -1:
                        this.examineFalseShow = true;
                        this.changeStp = 2;
                        break;
                    default:
                        break;
                }
            } else {
                switch (this.changeStatus) {
                    case -1: // 审核中
                        this.changeStp = 2;
                        break;
                    case -2: // 开始修改
                        this.changeStp = 1;
                        break;
                    case 0: // 修改不通过
                        this.editFalseShow = true;
                        this.changeStp = 1;
                        break;
                    default:
                        break;
                }
            }
        });
    }
    private resetInfo() {
        this.changeStp = 1;
        this.examineFalseShow = false;
        this.editFalseShow = false;
    }
    private receiveGoBack() {
        // this.$router.go(-1);
        this.certificationGoBackTodo(1);
    }
    @Emit('certificationGoBack')
    private certificationGoBackTodo(num: number): any {
        return num;
    }
}
