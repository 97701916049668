
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit  } from 'vue-property-decorator';

@Component({
    name: "DepartmentInfo",
})
export default class DepartmentInfo extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Prop(Number) private infoType!: number;
    @Prop(String) private title!: string;
    @Prop(Object) private formData!: object;

    private showDialog: boolean = false; // 弹窗显示与关闭
    private formTab = {
        name: '',
        superDep: '',
        option: '',
    };
    private switchTab = {
        ownDep: false,
        memberMail: true,
    };
    private options = [];
    private caption: string = "";
    private rules: any = {
        name: [
            { required: true, message: '请输入部门名称', trigger: 'blur' },
        ],
    };
    @Watch('exitFlag') // 监听父组件传过来的值
    private changeFlagFun(value: boolean) {
        this.showDialog = value;
    }
    @Watch('title') // 监听父组件传过来的值
    private titleFun(value: string) {
        this.caption = value;
    }
    @Watch('infoType') // 监听父组件传过来的值
    private infoTypeFun(value: number) {
        this.infoType = value;
    }
    @Watch('formData') // 监听父组件传过来的值
    private formDataFun(value: object) {
        this.formTab = JSON.parse(JSON.stringify(value));
    }

    // 给父组件传值
    @Emit('infoCloseMsg')
    private closeTodo(num: number): number {
        return num;
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        if (num === 3) {
            // 判断校验是否通过
            if (submitName !== undefined) {
                const formData = this.$refs[submitName]! as ElForm;
                formData.validate((valid) => {
                    if (valid) {
                        this.showDialog = false;
                        this.closeTodo(num); // 给父组件传值
                    } else {
                        return false;
                    }
                });
            }
        } else {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        }
    }
    // 弹窗关闭后清除所有数据
    private afterClose() {
        const elForm = this.$refs.formRef! as ElForm;
        elForm.resetFields();
    }
    // 右上角的关闭按钮
    private beforeclose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
}
