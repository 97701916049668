
import '@/assets/css/iframeStyle.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import { ElForm } from 'element-ui/types/form';
import { useMessage } from '@/utils/message'; // 方便弹出吐司
import DialogTool from "./component/DialogTool.vue";
import ProvinceCitySelect from "@/components/ProvinceCitySelect.vue";

@Component({
    name: "Address",
    components: {
        ContentTitle,
        DialogTool,
        ProvinceCitySelect,
    },
})
export default class Address extends Vue {
    private baseUrlIfram = process.env.VUE_APP_URL;
    private activePage: number = 1; // 1,是主页面; 2,子页面
    private editAddrType: number = 1; // 1,添加; 2,修改
    private total: number = 0;
    private contentTitle: string = "收货地址";
    // DialogTool
    private tabFlag: boolean = false;
    private title: string = "";
    private content: string = "";
    private btnText: string = "";
    private rowData: object = {};
    // end
    private addressData: any = {
        consignee: '',
        mobile: '',
        pageNo: 1,
        pageSize: 10,
    };
    private searchRules: any = {
        // user: [
        //     { required: true, message: '请输入收货人', trigger: 'change' },
        // ],
        // mobile: [
        //     { required: true, message: '请输入收货电话', trigger: 'change' },
        // ],
    };
    private addressTab: any = [
    //     {
    //     id: '291',
    //     contacts: 'test',
    //     mobile: '13564588896',
    //     addopt: 'xijiao',
    //     address: '北京',
    //     isDefault: 0,
    // }
    ];
    private optionProps: any = {
        value: 'value',
        label: 'name',
        children: 'children',
    };
    private addressOpt: any = [{
        value: 'beijing',
        name: '北京',
        children: [{
            value: 'beijingshi',
            name: '北京市',
            children: [
                {
                    value: 'xichengqu',
                    name: '西城区',
                },
                {
                    value: 'xijiao',
                    name: '西郊',
                },
            ],
        }],
    }];
    private addressForm: any = {
        id: 0,
        contacts: '',
        mobile: '',
        addopt: '',
        address: '',
        // addrProvince: 110000,
        // addrCity: 0,
        // addrDistrict: 0,
        provinceCode: 0,
        cityCode: 0,
        countyCode: 0,
        isDefault: 0,
    };
    private addressRules: any = {
        user: [
            { required: true, message: '请输入收货人', trigger: 'change' },
        ],
        mobile: [
            { required: true, message: '请输入收货人电话', trigger: 'change' },
        ],
        addopt: [
            { required: true, validator: this.validateAddOpt, trigger: 'blur' },
        ],
        address: [
            { required: true, message: '请输入地址详情', trigger: 'change' },
        ],
    };
    private validateAddOpt(rule: any, value: string, callback: any) {
        if (this.addressForm.countyCode === undefined) {
            callback(new Error('请选择城市'));
        // } else if (this.addressForm.addrDistrict === undefined) {
        //     callback(new Error('请选择地区'));
        } else {
            callback();
        }
    }
    private created() {
        // this.getAddrList();
    }
    private getAddrList() {
        this.$httpService.getData(this.addressData, this.$apiList.address_list).then((res: any) => {
            this.addressTab = res.list;
            this.total = res.total;
        });
    }
    private submitForm(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.validate((valid: boolean) => {
            if (valid) {
                if (this.editAddrType === 1) {
                    const addDetail = this.addressForm;
                    this.$httpService.putData(addDetail, this.$apiList.address_add)
                    .then((res: any) => {
                        this.activePage = 1;
                        this.contentTitle = "收货地址";
                        this.getAddrList();
                    });
                } else if (this.editAddrType === 2) {
                    const addDetail = this.addressForm;
                    this.$httpService.postData(addDetail,
                    this.$apiList.address_update.replace('%s', this.addressForm.id))
                    .then((res: any) => {
                        this.activePage = 1;
                        this.contentTitle = "收货地址";
                        this.getAddrList();
                    });
                }
            } else {
                useMessage(2, '输入的信息有误~');
                return false;
            }
        });
    }
    private addAddress() {
        this.activePage = 2;
        this.editAddrType = 1;
        this.contentTitle = "编辑收货地址";
        this.addressForm = {
            contacts: '',
            mobile: '',
            // addopt: '',
            address: '',
            provinceCode: 110000,
            // cityCode: 0,
            // countyCode: 0,
            isDefault: 0,
        };
    }
    // 接收子页面传回来的值
    private propGoBlack() {
        this.activePage = 1;
        this.contentTitle = "收货地址";
    }
    private handleClick(row: any) {
        this.$httpService.getData({}, this.$apiList.address_detail.replace('%s', row.id)).then((res: any) => {
            this.activePage = 2;
            this.editAddrType = 2;
            this.contentTitle = "编辑收货地址";
            this.addressForm = res;
        });
    }
    private delClick(row: any) {
        this.tabToolData(row, "删除", "删除收货地址", "删除选中的收货地址，是否继续删除？");
    }
    private setClick(row: any) {
        this.tabToolData(row, "更改", "更改默认地址", "更改选中的收货地址为默认地址，是否继续更改？");
    }
    private tabToolData(rowData: any, btnText: string, title: string, content: string) {
        this.tabFlag = true;
        this.rowData = rowData;
        this.btnText = btnText;
        this.title = title;
        this.content = content;
    }
    private tabCloseMsg(num: number) {
        this.tabFlag = false;
        this.getAddrList();
    }
    private searchHandle(formName: any) {
        // const elForm = this.$refs[formName]! as ElForm;
        // elForm.validate((valid: boolean) => {
        //     if (valid) {
        //         //
        //     } else {
        //         return false;
        //     }
        // });
        this.getAddrList();
    }
    private resetHandle(formName: any) {
        // const elForm = this.$refs[formName]! as ElForm;
        // elForm.resetFields();
        this.addressData = {
            consignee: '',
            mobile: '',
            pageNo: 1,
            pageSize: 10,
        };
    }
    private selectReturn(obj: any) {
        this.addressForm.provinceCode = obj.province;
        this.addressForm.cityCode = obj.city;
        this.addressForm.countyCode = obj.district;
    }
    private currentChange(e: any) {
        this.addressData.pageNo = e;
        this.getAddrList();
    }
}
