
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
@Component({
  name: 'DeleteTool',
})
export default class DeleteTool extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Prop(String) private title!: string;
    @Prop(String) private content!: string;
    @Prop(Object) private row!: object;
    @Prop(String) private btnText!: string;

    private showDialog: boolean = false; // 弹窗显示与关闭
    private dialogData: any = {
        title: '',
        content: '',
        row: {},
        btnText: '',
    };

    @Watch('exitFlag') // 监听父组件传过来的值
    private exitFlagFun(value: boolean) {
        this.showDialog = value;
    }
    @Watch('title') // 监听父组件传过来的值
    private titleFun(value: string) {
        this.dialogData.title = value;
    }
    @Watch('content') // 监听父组件传过来的值
    private contentFun(value: string) {
        this.dialogData.content = value;
    }
    @Watch('btnText') // 监听父组件传过来的值
    private btnTextFun(value: string) {
        this.dialogData.btnText = value;
    }
    @Watch('row') // 监听父组件传过来的值
    private rowFun(value: object) {
        this.dialogData.row = value;
    }

    // 给父组件传值
    @Emit('tabCloseMsg')
    private closeTodo(num: number): number {
        return num;
    }
    //  确定按钮
    private closeDialog(num: number) {
        if (num === 3) {
            // this.showDialog = false;
            // this.closeTodo(num); // 给父组件传值
        } else {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        }
    }
    // 弹窗关闭后清除所有数据
    private afterClose() {
        // this.form = '';
    }
    // 右上角的关闭按钮
    private beforeclose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
}
