
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { verifyEmail } from '@/utils/utils';

@Component({
  name: 'PhoneEditEmail',
})
export default class PhoneEditEmail extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private emailEditFlag!: boolean; // 接收父组件传过来的值

    private showDialog: boolean = false; // 弹窗显示与关闭    // 倒计时相关变量
    private codeFlag: boolean = false;
    private time: number = 60;
    private interVal: any = null;
    private form = {
        email: '',
        regCode: '',
    };
    private rules: any = {
        email: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
            { validator: this.validatorEmail, trigger: 'blur' },
        ],
        regCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' },
            // { validator: this.sendCode, trigger: 'blur' },
        ],
    };
    private validatorEmail(ule: any, value: any, callback: any) {
        if (value === '') {
            callback(new Error('请输入邮箱'));
        } else if (!verifyEmail(value)) {
            callback(new Error('邮箱格式不正确!'));
        } else {
            callback();
        }
    }
    private sendCode(rule: any, value: any, callback: any) {
        if (this.codeFlag) {
            callback();
        } else {
            callback(new Error('请先点击发送验证码'));
        }
    }

    @Watch('emailEditFlag') // 监听父组件传过来的值
    private emailEditFlagFun(value: boolean) {
        this.showDialog = value;
    }

    // 给父组件传值
    @Emit('emailCloseMsg')
    private closeTodo(num: number): number {
        // 清除倒计时
        this.codeFlag = false;
        clearInterval(this.interVal);
        this.time = 60;
        this.resetForm('form');
        return num;
    }
    private resetForm(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.resetFields();
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        if (num === 3) {
            // 判断校验是否通过
            if (submitName !== undefined) {
                const formData = this.$refs[submitName]! as ElForm;
                formData.validate((valid) => {
                    if (valid) {
                        const paramData: any = {};
                        paramData.email = this.form.email;
                        paramData.captcha = this.form.regCode;
                        this.$httpService.postData(paramData, '/apiProxy/api/frontend/users/email/bind')
                        .then((res: any) => {
                            this.$message({
                                message: '绑定成功!',
                                type: 'success',
                            });
                            this.showDialog = false;
                            this.closeTodo(num); // 给父组件传值
                        });

                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        } else {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        }
    }
    // 弹窗关闭后清除所有数据
    private afterClose() {
        this.form.regCode = '';
    }
    // 右上角的关闭按钮
    private beforeclose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }

    private getCode() {
        if (!verifyEmail(this.form.email)) {
            this.$message.warning('邮箱格式不正确!');
            return;
        }
        this.$httpService.getData({email: this.form.email},
        '/apiProxy/api/frontend/users/email/captcha')
        .then((res: any) => {
            this.countDown();
        });
    }

    // 验证码倒计时
    private countDown() {
        this.time = 60;
        this.codeFlag = true;
        this.interVal = setInterval(() => {
            this.time --;
            if (this.time === -1) {
                this.codeFlag = false;
                clearInterval(this.interVal);
            }
        }, 1000);
    }


}
