
import { Component, Vue, Prop, Watch, Emit  } from 'vue-property-decorator';
import ProvinceCitySelect from "@/components/ProvinceCitySelect.vue";
import { verifyPhone } from '@/utils/utils';
@Component({
    name: "InvoiceInfo",
    components: {
        ProvinceCitySelect,
    },
})
export default class InvoiceInfo extends Vue {
    @Prop(Object) private content!: object;
    private userInvoice: any = {};
    private rules: any = {
        mobile: [
            // { required: true, message: '请输入手机号', trigger: 'change' },
            { validator: this.validateMobile, trigger: 'change' },
        ],
    };
    @Watch('content', {immediate: true, deep: true})
    private contentFun(data: any) {
        this.userInvoice = data;
    }
    private selectReturn(obj: any) {
        this.userInvoice.cityCode = obj.city;
        this.userInvoice.provinceCode = obj.province;
    }
    @Emit('invoiceReload')
    private reloadData() {
        return true;
    }
    private validateMobile(rule: any, value: string, callback: any) {
        if (value !== '' && value !== null && !verifyPhone(value)) {
            callback(new Error('手机号格式不正确'));
        } else {
            callback();
        }
    }
    private onSubmit() {
        this.$httpService.putData(this.userInvoice, '/apiProxy/api/frontend/company/info/invoice')
        .then((res: any) => {
            this.reloadData();
            this.$message({
                message: '保存成功！',
                type: 'success',
            });
        });
    }
}
