
import '@/assets/css/iframeStyle.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import TableToolDialog from "./component/TableToolDialog.vue";
import CustomerInfo from "./component/CustomerInfo.vue";

@Component({
    name: "Customer",
    components: {
        ContentTitle,
        TableToolDialog,
        CustomerInfo,
    },
})
export default class Customer extends Vue {
    private baseUrlIfram = process.env.VUE_APP_URL;
    private contentTitle: string = "客服管理";
    private tabFlag: boolean = false;
    private rowData: any = {};
    private infoFlag: boolean = false;
    private infoTitle: string = "";
    private formData: object = {};
    private infoType: number = 0;
    private tableData = [];
    private total: number = 0;
    private pageNo: number = 1;
    private pageSize: number = 10;
    private searchParam: any = {
        pageNo: 1,
        pageSize: 10,
        name: '',
        mobile: '',
    };
    private onSearch() {
        this.searchParam.pageNo = 1;
        this.getInfo();
    }
    private resetForm(formName: any) {
        this.searchParam = {
            pageSize: 10,
            pageNo: 1,
            name: '',
            mobile: '',
        };
        const formRef: any = this.$refs[formName];
        formRef.resetFields();
        this.$nextTick(() => {
            this.getInfo();
        });
    }

    private created() {
        // 获取列表信息
        this.getInfo();
    }
    private getInfo() {
        const searchParam: any = Object.assign({}, this.searchParam);
        this.$httpService.getData(searchParam, '/apiProxy/api/frontend/company/customers').then((res: any) => {
            this.tableData = res.list;
            this.total = res.total;
        });
    }
    private handleSizeChange(val: number) {
        this.searchParam.pageSize = val;
        this.getInfo();
    }
    private handleCurrentChange(val: number) {
        this.searchParam.pageNo = val;
        this.getInfo();
    }
    private handleClick(row: any) {
        this.customerInfo(2, "修改客服信息", row);
    }
    private addCustomer() {
        this.customerInfo(1, "添加客服", {});
    }
    private customerInfo(infoType: number, infoTitle: string, formData: object) {
        this.infoFlag = true;
        this.infoType = infoType;
        this.infoTitle = infoTitle;
        if (infoType === 2) {
            this.$httpService.getData({}, '/apiProxy/api/frontend/company/customers/' + (formData as any).id)
            .then((res: any) => {
                this.formData = Object.assign({password: '******'}, res);
            });
        } else {
            this.formData = Object.assign({}, formData);
        }
    }
    private resetClick(row: any) {
        this.tabFlag = true;
        this.rowData = row;
    }
    private delClick(row: any) {
        this.$confirm(`确定要删除客服“${row.name}”吗？`, '删除', {
            confirmButtonText: '确定删除',
            cancelButtonText: '取消',
            type: 'warning',
            confirmButtonClass: 'el-button--danger',
        }).then(() => {
            // 确定
            this.rowData = row;
            this.deleteRequest();
        }).catch(() => {
            // 取消
        });
    }
    private deleteRequest() {
        this.$httpService.deleteData({},
        '/apiProxy/api/frontend/company/customers/' + this.rowData.id)
        .then((res: any) => {
            this.$message({
                message: '删除成功！',
                type: 'success',
            });
            this.getInfo();
        });
    }
    private fobiddenClick(row: any) {
        const title = row.status === 0 ? '启用' : '禁用';
        this.$confirm(`确定要${title}客服“${row.name}”吗？`, title, {
            confirmButtonText: title,
            cancelButtonText: '取消',
            type: 'warning',
            confirmButtonClass: row.status === 0 ? '' : 'el-button--danger',
        }).then(() => {
            // 确定
            this.$httpService.putData({},
            '/apiProxy/api/frontend/company/customers/' + row.id + '/status')
            .then((res: any) => {
                this.$message({
                    message: `${title}成功！`,
                    type: 'success',
                });
                this.getInfo();
            });
        }).catch(() => {
            // 取消
        });
    }
    private tabCloseMsg(num: number) {
        this.tabFlag = false;
        if (num === 3) {
            this.getInfo();
        }
    }
    private infoCloseMsg(num: number) {
        this.infoFlag = false;
        if (num === 3) {
            this.getInfo();
        }
    }
}
