
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit  } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";

@Component({
    name: "CustomerInfo",
    components: {
        ContentTitle,
    },
})
export default class CustomerInfo extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Prop(Number) private infoType!: number;
    @Prop(String) private title!: string;
    @Prop(Object) private formData!: object;
    private showDialog: boolean = false; // 弹窗显示与关闭
    private formTab = {
        weixin: '',
        phone: '',
        desc: '',
        password: '',
        email: '',
        isDefault: '0',
        name: '',
        qq: '',
        account: '',
        status: '',
        type: '1',
    };
    private caption: string = "";
    private rules: any = {
        name: [
            { required: true, message: '请输入新手机号', trigger: 'change' },
        ],
        phone: [
            { required: true, message: '请输入电话', trigger: 'change' },
        ],
        type: [
            { required: true, message: '请选择类型', trigger: 'change' },
        ],
        password: [
            { required: true, message: '请输入密码', trigger: 'change' },
            { validator: this.validateOldPwd, trigger: 'change' },
        ],
    };
    private validateOldPwd(rule: any, value: string, callback: any) {
        if (value === '') {
            callback(new Error('请输入密码'));
        } else if (value !== '' && (value.length < 6 || value.length > 30)) {
            callback(new Error('请输入6-30位密码'));
        } else {
            callback();
        }
    }
    @Watch('exitFlag') // 监听父组件传过来的值
    private changeFlagFun(value: boolean) {
        this.showDialog = value;
        if (value) {
            this.caption = this.title;
        }
    }
    @Watch('formData') // 监听父组件传过来的值
    private formDataFun(value: number) {
        if (this.infoType === 2) {
            this.formTab = JSON.parse(JSON.stringify(value));
            this.formTab.type = this.formTab.type.toString() === '0' ? '' : this.formTab.type.toString();
            this.formTab.isDefault = this.formTab.isDefault.toString();
        }
    }
    // 给父组件传值
    @Emit('infoCloseMsg')
    private closeTodo(num: number): number {
        return num;
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        if (num === 3) {
            // 判断校验是否通过
            if (submitName !== undefined) {
                const elForm = this.$refs[submitName]! as ElForm;
                const formData: any = {...this.formTab};
                elForm.validate((valid) => {
                    if (valid) {
                        if (this.infoType === 1) {
                            this.$httpService.putData(formData, '/apiProxy/api/frontend/company/customers')
                            .then((res: any) => {
                                this.showDialog = false;
                                this.closeTodo(num); // 给父组件传值
                                this.$message({
                                    message: '添加成功！',
                                    type: 'success',
                                });
                            });
                        } else if (this.infoType === 2) {
                            delete formData.password;
                            this.$httpService.postData(formData,
                            '/apiProxy/api/frontend/company/customers/' + (this.formTab as any).id)
                            .then((res: any) => {
                                this.showDialog = false;
                                this.closeTodo(num); // 给父组件传值
                                this.$message({
                                    message: '修改成功！',
                                    type: 'success',
                                });
                            });
                        }
                    } else {
                        return false;
                    }
                });
            }
        } else {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        }
    }
    // 弹窗关闭后清除所有数据
    private afterClose() {
        const elForm = this.$refs.formRef! as ElForm;
        elForm.resetFields();
    }
    // 右上角的关闭按钮
    private beforeclose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
}
