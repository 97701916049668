
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit  } from 'vue-property-decorator';
import ProvinceCitySelect from "@/components/ProvinceCitySelect.vue";
import PhoneEditDialog from "@/components/PhoneEditDialog.vue";
import PhoneEditEmail from "../../security/component/PhoneEditEmail.vue";
import UE from "@/components/RichTextEditor/ueditor.vue";
import { verifyPhone } from '@/utils/utils';

@Component({
    name: "BaseInfo",
    components: {
        UE,
        ProvinceCitySelect,
        PhoneEditDialog,
        PhoneEditEmail,
    },
})
export default class BaseInfo extends Vue {
    @Prop(Object) private content!: object;
    private baseUrlIframe = process.env.VUE_APP_URL;
    private showTips: boolean = false;
    private inputVisible: boolean = false;
    private inputValue: string =  '';
    private phoneEditFlag: boolean = false; // 手机修改弹窗
    private emailEditFlag: boolean = false; // 邮箱绑定弹窗
    private editUserInfo: boolean = false;
    private loading: boolean = true;
    private userInfo: any = this.$store.state.userInfo;
    private companyInfo: any = {};
    private fileList: any = [];
    private formData: any = {
        topContacts: null,
        synopsis: null, // 简介
        contactsPosition: null, // 职务
        name: null,
        topMobile: null,
        topEmail: null,
        industryLevelList: [],  // 所属行业
        logo: null, // 标签
        topAddress: null,
        intro: '',
    };
    private baseUrl: string = '';
    private rules: any = {
        topContacts: [
            { required: true, message: '请输入常用联系人', trigger: 'blur' },
        ],
    };
    private ueditorConfig: any = {
        autoHeightEnabled: false,
        autoFloatEnabled: false,
        initialFrameHeight: 300,
        initialFrameWidth: null, // 关闭字数统计
        wordCount: false, // 关闭elementPath
        elementPathEnabled: false,
        enableAutoSave: false,
        // serverUrl:  "/apiProxy/api/frontend/file/upload_image",
        toolbars: [[
            'fullscreen', 'source', '|', 'undo', 'redo', '|',
            'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
            'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
            'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
            'directionalityltr', 'directionalityrtl', 'indent', '|',
            'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|',
            'link', 'unlink', 'anchor', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
            'simpleupload', 'insertimage', 'emotion', 'scrawl', 'insertvideo', 'attachment', 'map', 'insertframe', 'insertcode', 'webapp', 'pagebreak', 'template', 'background', '|',
            'horizontal', 'date', 'time', 'spechars', 'snapscreen', 'wordimage', '|',
            'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols', 'charts', '|',
            'print', 'preview', 'searchreplace', 'help', 'drafts',
        ]],
    };
    // 多级联动
    private props: any = {
        lazy: true,
        lazyLoad: this.industryLazyLoad,
    };
    // 判断是否显示个人信息顶部提示
    private loopFormData() {
        this.showTips = false;
        const arr: any = Object.keys(this.formData);
        for (const key of arr) {
            const value = this.formData[key];
            // console.log(key, value);
            if (typeof(value) === 'string' && value.length <= 0) {
                this.showTips = true;
                break;
            }
            if (typeof(value) === 'number' && (value === null || value === undefined)) {
                this.showTips = true;
                break;
            }
            if (typeof(value) === 'object' && value.length <= 0) {
                this.showTips = true;
                break;
            }
            if (key === 'industryLevelList' && value[0] === null) {
                this.showTips = true;
                break;
            }
        }
    }
    private industryLazyLoad(node: any, resolve: any) {
        const { level } = node;
        const paramData: any = {};
        paramData.parentId = node.value ? node.value : 0;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/config/industrial-categories')
        .then((res: any) => {
            const nodes: any = [];
            if (res && res.length > 0) {
                res.forEach((item: any) => {
                    nodes.push({
                        value: item.id,	// 选项值
                        label: item.name,	// 选项名
                        leaf: level >= 3,	// 是否为末尾
                    });
                });
            }
            resolve(nodes);
        });
    }
    private handleCascadeVisibleChange(val: any) {
        if (!val) {
            const sysCascadeRef: any = this.$refs.sysCascade;
            const panelRefs: any = sysCascadeRef.$refs.panel;
            if (sysCascadeRef) {
                // panelRefs.checkedValue = []; // 也可以是指定的值，默认返回值是数组，也可以返回单个值
                panelRefs.activePath = [];
                panelRefs.syncActivePath();
            }
        }
    }
    private validateMobile(rule: any, value: string, callback: any) {
        if (value !== '' && value !== null && !verifyPhone(value)) {
            callback(new Error('手机号格式不正确'));
        } else {
            callback();
        }
    }
    @Watch('content', {immediate: true, deep: true})
    private contentFun(data: any) {
        const info = Object.assign({}, data);
        if (info.industryLevelList && info.industryLevelList.length && info.industryLevelList[0] === null) {
            info.industryLevelList = [];
        }
        this.companyInfo = Object.assign({}, info);
        this.resetFormData(info);
        this.$nextTick(() => {
            this.loading = false;
        });
    }
    @Emit('showPageTwo')
    private toCertification() {
        return true;
    }
    private created() {
        if (process.env.NODE_ENV === 'development') {
            this.baseUrl = '/apiProxy';
        }
    }
    @Emit('reload')
    private reloadData() {
        return true;
    }
    private onSubmit(submitName: string) {
        const elForm = this.$refs[submitName]! as ElForm;
        elForm.validate((valid) => {
            if (valid) {
                this.$httpService.putData(this.companyInfo, '/apiProxy/api/frontend/company/info/base')
                .then((res: any) => {
                    this.reloadData();
                    this.$message({
                        message: '保存成功！',
                        type: 'success',
                    });
                });
            }
        });
    }
    private answerContent(value: any) {
        this.formData.intro = value;
    }
    // 选择头像
    private chooseFile(event: any) {
        const fileData = event.target.files[0];
        if (this.beforeUpload(fileData) === false) {
            return;
        }
        const param = new FormData();
        param.append('file', fileData);
        this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        }).then((res: any) => {
            this.companyInfo.logo = res.path;
            this.$httpService.putData({avatar: res.path},
            '/apiProxy/api/frontend/users/avatar')
            .then((putRes: any) => {
                this.$message({
                    message: '修改成功！',
                    type: 'success',
                });
                this.reloadData();
                this.saveStoreUserInfo({avatar: res.path});
            });
        });
    }
    private resetFormData(res: any) {
        this.formData = {
            topContacts: res.topContacts,
            contactsPosition: res.contactsPosition,
            name: res.name,
            industryLevelList: [].concat(res.industryLevelList),  // 所属行业
            topAddress: res.topAddress, // 标签
            topEmail: res.topEmail,
            topMobile: res.topMobile,
            intro: res.intro || '',
            logo: res.logo,
        };
        const formRef = this.$refs.formData! as ElForm;
        if (formRef) {
            formRef.resetFields();
        }
        const ueRef: any = this.$refs.UE;
        if (ueRef) {
            ueRef.setUEContent(res.intro);
        }
        this.loopFormData();
    }
    // 修改全局用户信息
    private saveStoreUserInfo(info: any) {
        const saveUserInfo = {
            ...this.$store.state.userInfo,
            ...info,
        };
        this.$store.commit('saveUserInfo', saveUserInfo);
    }
    // 修改企业信息
    private saveCompanyInfo() {
        // const ueRef: any = this.$refs.UE;
        // console.log(ueRef.getUEContentText());
        const formRef = this.$refs.formData! as ElForm;
        formRef.validate((valid) => {
            if (valid) {
                this.$httpService.putData(this.formData, '/apiProxy/api/frontend/company/info/base')
                .then((res: any) => {
                    this.$message({
                        message: '修改成功！',
                        type: 'success',
                    });
                    this.reloadData();
                    this.editUserInfo = false;
                });
            }
        });
    }
    // 修改个人信息
    private startEditUser() {
        this.editUserInfo = true;
    }
    // 修改个人信息
    private endEditUser() {
        this.editUserInfo = false;
        this.resetFormData(this.companyInfo);
        // this.formData.labels = this.userInfo.labels;
        // this.resetFormData(this.userInfo);
    }
    // 手机修改弹窗
    private phoneEdit() {
        this.phoneEditFlag = true;
    }
    // 接收手机关闭弹窗传回来的值
    private phoneCloseMsg(num: number) {
        this.phoneEditFlag = false;
        if (num === 3) {
            // 点击了下一步
            this.reloadData();
        }
    }
    private bindEmail() {
        this.emailEditFlag = true;
    }
    private emailCloseMsg(num: number) {
        this.emailEditFlag = false;
        if (num === 3) {
            this.reloadData();
        }
    }
    private beforeUpload(file: any) {
        console.log('file', file);
        const fileType = file.type;
        const fileName = file.name;
        // 根据后缀判断文件类型
        let fileSuffix = "";
        try {
            const fileArr = fileName.split(".");
            fileSuffix = fileArr[fileArr.length - 1];
        } catch (err) {
            fileSuffix = "";
        }
        const isCorrect = (
            fileType === 'image/png'
            || fileType === 'image/jpg'
            || fileType === 'image/jpeg'
        );
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            this.$message.error('上传文件大小不能超过 2MB!');
            return false;
        }
        // this.file = null;
        this.fileList = [];
        if (!isCorrect) {
            this.$message.error('上传图片格式错误!');
            return false;
        }
    }
    private httpRequestUpload(data: any) {
        const param = new FormData();
        param.append('file', data.file);
        this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
        .then((res: any) => {
            this.fileList = [data.file];
            this.formData.logo = res.path;
        }).catch(() => {
            this.fileList = [];
        });
    }
    // 选择文件
    private openFile() {
        const file: any = this.$refs.file;
        file.click();
    }
}
