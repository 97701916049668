
import { Component, Vue, Prop, Watch, Emit  } from 'vue-property-decorator';
import ProvinceCitySelect from "@/components/ProvinceCitySelect.vue";
@Component({
    name: "CardInfo",
    components: {
        ProvinceCitySelect,
    },
})
export default class CardInfo extends Vue {
    @Prop(Object) private content!: object;
    private userBank: any = {};
    @Watch('content', {immediate: true, deep: true})
    private contentFun(data: any) {
        this.userBank = data;
    }
    private selectReturn(obj: any) {
        this.userBank.cityCode = obj.city;
        this.userBank.provinceCode = obj.province;
    }
    @Emit('bankReload')
    private reloadData() {
        return true;
    }
    private onSubmit() {
        this.$httpService.putData(this.userBank, '/apiProxy/api/frontend/company/info/bank')
        .then((res: any) => {
            this.reloadData();
            this.$message({
                message: '保存成功！',
                type: 'success',
            });
        });
    }
}
