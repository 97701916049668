
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import ImgLarge from "./LargeImg.vue";
import ProvinceCitySelect from "@/components/ProvinceCitySelect.vue";
import { ElForm } from 'element-ui/types/form';
import { compressAccurately } from 'image-conversion'; // 按需引入

@Component({
    name: "EditInfo",
    components: {
        ImgLarge,
        ProvinceCitySelect,
    },
})
export default class EditInfo extends Vue {
    @Prop(Object) private resData!: object;
    private activeName: string = "first";
    private enlargeFlag: boolean = false;
    private enlargeSrc: string = ""; // 传给子组件的图片url
    private num: string = ""; // 传给子组件的图片类型
    // 表单字段
    private businessTab: any = {
        photo: '',
        businessLicensePic: require('@/assets/images/header/yyzz.png'), // 营业执照副本
        name: '', // 企业名称
        documentType: '2', // 证件类型  1非三证合一2 三证合一 3 五证合一
        registrationNumber: '', // 营业执照注册号
        taxpayerNumber: '', // 纳税人识别号
        locationAddress: '',
        address: '', // 详细地址
        provinceName: '', // 省
        cityName: '', // 市
        provinceCode: null,
        cityCode: null,
        industry: [],
    };
    private businessRules: any = {
        photo: [
            { required: true, message: '请上传营业执照副本', trigger: 'change' },
        ],
        name: [
            { required: true, message: '请输入企业名称', trigger: 'change' },
        ],
        // documentType: [
        //     { required: true, message: '证件类型', trigger: 'blur' },
        // ],
        registrationNumber: [
            { required: true, message: '请输入营业执照注册号', trigger: 'change' },
        ],
        // taxpayerNumber: [
        //     { required: true, message: '请输入纳税人识别号', trigger: 'change' },
        // ],
        address: [
            { required: true, message: '请输入详细地址', trigger: 'change' },
        ],
        cityCode: [
            { required: true, message: '营业执照所在地', trigger: 'blur' },
        ],
        industry: [
            { required: true, message: '请选择行业', trigger: 'change' },
        ],
    };
    private contactTab: any = {
        idCardFrontPhoto: '',
        businessEntityPicA:  require('@/assets/images/header/sfz_1.png'), // 身份证正面照
        idCardBackPhoto: '',
        businessEntityPicB:  require('@/assets/images/header/sfz_2.png'), // 身份证背面照
        name: '', // 联系人姓名
        idCardNumber: '', // 联系人身份证号
    };
    private contactRules: any = {
        idCardFrontPhoto: [
            { required: true, message: '请上传身份证正面照', trigger: 'change' },
        ],
        idCardBackPhoto: [
            { required: true, message: '请上传身份证背面照', trigger: 'change' },
        ],
        name: [
            { required: true, message: '请输入联系人姓名', trigger: 'blur' },
        ],
        idCardNumber: [
            { required: true, message: '请输入联系人身份证号', trigger: 'blur' },
        ],
    };
    private certStatus: number = 0;
    // 多级联动
    private props: any = {
        lazy: true,
        lazyLoad: this.industryLazyLoad,
    };
    private industryLazyLoad(node: any, resolve: any) {
        const { level } = node;
        const paramData: any = {};
        paramData.parentId = node.value ? node.value : 0;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/config/industrial-categories')
        .then((res: any) => {
            const nodes: any = [];
            if (res && res.length > 0) {
                res.forEach((item: any) => {
                    nodes.push({
                        value: item.id,	// 选项值
                        label: item.name,	// 选项名
                        leaf: level >= 3,	// 是否为末尾
                    });
                });
            }
            resolve(nodes);
        });
    }
    @Watch('resData', {immediate: true, deep: true}) // 监听父组件传过来的值
    private resDataFun(value: number) {
        const businessLicense = JSON.parse(JSON.stringify(value)).businessLicense;
        const contact = JSON.parse(JSON.stringify(value)).contact;
        const industryLevelList = JSON.parse(JSON.stringify(value)).industryLevelList;
        this.certStatus = JSON.parse(JSON.stringify(value)).certStatus;
        if (businessLicense) {
            this.businessTab = {
                photo: businessLicense.photo,
                businessLicensePic: require('@/assets/images/header/yyzz.png'), // 营业执照副本
                name: businessLicense.name, // 企业名称
                documentType: businessLicense.documentType + '', // 证件类型  1非三证合一2 三证合一 3 五证合一
                registrationNumber: businessLicense.registrationNumber, // 营业执照注册号
                taxpayerNumber: businessLicense.registrationNumber, // 纳税人识别号
                address: businessLicense.address, // 详细地址
                provinceCode: businessLicense.provinceCode,
                cityCode: businessLicense.cityCode,
                industry: industryLevelList,
            };
        }
        if (contact) {
            this.contactTab = {
                idCardFrontPhoto: contact.idCardFrontPhoto,
                businessEntityPicA:  require('@/assets/images/header/sfz_1.png'), // 身份证正面照
                idCardBackPhoto: contact.idCardBackPhoto,
                businessEntityPicB:  require('@/assets/images/header/sfz_2.png'), // 身份证背面照
                name: contact.name, // 联系人姓名
                idCardNumber: contact.idCardNumber, // 联系人身份证号
            };
        }
    }
    private created() {
        // ...
    }
    private selectReturn(obj: any) {
        this.businessTab.cityCode = obj.city;
        this.businessTab.provinceCode = obj.province;
        if (this.businessTab.cityCode) {
            const businessForm = this.$refs.businessForm! as ElForm;
            businessForm.validateField('cityCode');
        }
    }
    // 添加营业执照副本
    private httpRequestBusiness(data: any) {
        const param = new FormData();
        param.append('file', data.file);
        this.$httpService.postData(param, '/apiProxy/api/frontend/util/parse/business_license?tolerant=1', {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
        .then((res: any) => {
            this.businessTab.name = res.name || ''; // 企业名称
            // this.businessTab.documentType = res.category + ''; // 证件类型
            this.businessTab.registrationNumber = res.socialCreditCode || ''; // 营业执照注册号
            if (!res.identityNumber || res.identityNumber === '' || res.identityNumber === '无') {
                this.businessTab.taxpayerNumber = res.socialCreditCode || ''; // 纳税人识别号
            } else {
                this.businessTab.taxpayerNumber = res.identityNumber || ''; // 纳税人识别号
            }
            this.businessTab.address = res.address || ''; // 详细地址
            this.businessTab.photo = res.remoteUrl; // 远程图片地址
            const businessForm = this.$refs.businessForm! as ElForm;
            businessForm.validateField('photo');
        });
    }
    // 添加身份证正面
    private httpRequestIdentity(data: any) {
        const param = new FormData();
        param.append('file', data.file);
        this.$httpService.postData(param, '/apiProxy/api/frontend/util/parse/identity_card', {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
        .then((res: any) => {
            this.contactTab.name = res.name;
            this.contactTab.idCardNumber = res.number;
            this.contactTab.idCardFrontPhoto = res.remoteUrl;
            const contactForm = this.$refs.contactForm! as ElForm;
            contactForm.validateField('idCardFrontPhoto');
        });
    }
    // 添加身份证反面
    private httpRequestIdentityB(data: any) {
        const param = new FormData();
        param.append('file', data.file);
        this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
        .then((res: any) => {
            this.contactTab.idCardBackPhoto = res.path;
            const contactForm = this.$refs.contactForm! as ElForm;
            contactForm.validateField('idCardBackPhoto');
        });
    }
    private beforeAvatarUpload(file: any) {
        // 注意这里需要用Promise函数来阻止图片没有压缩好,就直接将文件上传
        return new Promise(async (resolve: any, reject: any) => {
            const isJPG = file.type;
            const isLt2M = file.size / 1024 / 1024 < 10;
            const imgType = file.type;
            const imgName = file.name;
            if (isJPG !== "image/jpeg" && isJPG !== "image/jpg"  && isJPG !== "image/png") {
                this.$message.warning('支持.jpg、.jpeg、.png格式图片上传，建议使用png图片格式');
                return reject(false);  // 注意这里需要用reject来返回错误的信息,防止图片自动上传
            }
            if (!isLt2M) {
                this.$message.warning('上传图片大小不能超过 10MB!');
                return reject(false);  // 注意这里需要用reject来返回错误的信息,防止图片自动上传
            }
            const compress = 2048;  // 假设图片限制不能大于2M
            const sizeOver = file.size / 1024 > compress; // 文件大小 是否大于指定大小
            if (sizeOver && compress) { // 大于2M进行压缩
                const res = await compressAccurately(file, {
                    size: compress, // 需要压缩的大小
                    accuracy: 0.9, // 精度 0.8-0.99之间 默认值0.95
                    type: imgType,
                    scale: 0.5,
                });
                file = res; // 把得到的新的图片文件赋值给原文件,然后进行上传   得到的是bold对象
                file = new window.File([file], imgName, {type: imgType}); // 将bold对象转换为file文件
            }
            resolve(file); // 通过resolve将Promise函数返回成功回调,进行后面操作
        });
    }
    // 提交审核事件
    private onSubmit() {
        const businessForm = this.$refs.businessForm as ElForm;
        businessForm.validate((valid: boolean) => {
            if (valid) {
                this.validateContact();
            }
        });
        const contactForm = this.$refs.contactForm as ElForm;
        contactForm.validate();
    }
    private validateContact() {
        const contactForm = this.$refs.contactForm as ElForm;
        contactForm.validate((valid: boolean) => {
            if (valid) {
                if (this.certStatus === 2) {
                    this.editRequest();
                } else {
                    this.createRequest();
                }
            }
        });
    }
    private createRequest() {
        const paramData: any = {};
        paramData.businessLicensePhoto = this.businessTab.photo;
        // paramData.businessLicenseType = this.businessTab.documentType;
        paramData.companyName = this.businessTab.name;
        // paramData.companyShortName = this.businessTab.companyShortName; // 公司简称
        paramData.provinceCode = this.businessTab.provinceCode;
        paramData.cityCode = this.businessTab.cityCode;
        paramData.identityNumber = this.businessTab.registrationNumber;
        paramData.registrationNumber = this.businessTab.registrationNumber;
        paramData.registrationAddress = this.businessTab.address;

        paramData.idCardFrontPhoto = this.contactTab.idCardFrontPhoto;
        paramData.idCardBackPhoto = this.contactTab.idCardBackPhoto;
        paramData.legalPerson = this.contactTab.name;
        paramData.idCardNumber = this.contactTab.idCardNumber;

        paramData.certStatus = 1;

        paramData.industryLevelList = this.businessTab.industry;
        this.$httpService.putData(paramData, '/apiProxy/api/frontend/company/info/certification')
        .then((res: any) => {
            this.$alert("提交审核成功!", '提示', {
                showClose: false,
                confirmButtonText: '确定',
                callback: (action) => {
                    this.submitHandleTodo(1);
                },
            });
        });
    }
    private editRequest() {
        const param = {
            businessLicense: {},
            contact: {},
            industryLevelList: [],
        };
        this.businessTab.taxpayerNumber = this.businessTab.registrationNumber;
        param.businessLicense = this.businessTab;
        param.contact = this.contactTab;
        param.industryLevelList = this.businessTab.industry;
        this.$httpService.postData(param, '/apiProxy/api/frontend/company/info/certification/change')
        .then((res: any) => {
            this.$alert("成功提交审核!", '提示', {
                showClose: false,
                confirmButtonText: '确定',
                callback: (action) => {
                    this.submitHandleTodo(2);
                },
            });
        });
    }
    // 图片放大功能
    private previewImg(str: string, num: string) {
        this.enlargeFlag = true;
        this.enlargeSrc = str;
        this.num = num;
    }
    // 监听子组件传回来的弹窗关闭标志
    private propChildImgFun(val: boolean) {
        this.enlargeFlag = val;
    }
    private getImageUrl(url: string) {
        if (url.startsWith("http") || url.startsWith("https")) {
            return url;
        }
        return process.env.VUE_APP_URL + url;
    }
    @Emit('submitHandle')
    private submitHandleTodo(num: number): any {
        return num;
    }
    private handleCascadeVisibleChange(val: any) {
        if (!val) {
            const sysCascadeRef: any = this.$refs.sysCascade;
            const panelRefs: any = sysCascadeRef.$refs.panel;
            if (sysCascadeRef) {
                // panelRefs.checkedValue = []; // 也可以是指定的值，默认返回值是数组，也可以返回单个值
                panelRefs.activePath = [];
                panelRefs.syncActivePath();
            }
        }
    }
}
