
import '@/assets/css/iframeStyle.scss';
import { Component, Vue } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import { ElForm } from 'element-ui/types/form';
import { useMessage } from '@/utils/message'; // 方便弹出吐司
import OrganizationInfo from "./component/OrganizationInfo.vue";
import DepartmentInfo from "./component/DepartmentInfo.vue";
import DeleteTool from "./component/DeleteTool.vue";
import EmptySelectDialog from "@/components/EmptySelectDialog.vue";

@Component({
    name: "organization",
    components: {
        ContentTitle,
        OrganizationInfo,
        DepartmentInfo,
        DeleteTool,
        EmptySelectDialog,
    },
})
export default class Organization extends Vue {
    private baseUrlIfram = process.env.VUE_APP_URL;
    private activePage: number = 1; // 1,是主页面; 2,批量添加部门
    private contentTitle: string = "组织机构";
    private isShowDeclare: boolean = false;
    private notUpload: boolean = true;
    // DeleteTool
    private tabFlag: boolean = false;
    private title: string = "";
    private content: string = "";
    private btnText: string = "";
    private rowData: object = {};
    // end
    // OrganizationInfo
    private infoFlag: boolean = false;
    private infoTitle: string = "";
    private formData: object = {};
    // end
    // DepartmentInfo
    private depFlag: boolean = false;
    private depTitle: string = "";
    private depData: object = {};
    // end
    // EmptySelectDialog
    private emptyFlag: boolean = false;
    private contentTxt: string = "";
    // end
    private selectDep: any = [];
    private organizeTab: any = [
        {
            id: 1,
            flag: false,
            checked: false,
            name: '成都朗杰电子科技有限公司',
            number: '6',
            director: '周远强',
            range: '全员',
        },
        {
            id: 2,
            flag: true,
            checked: false,
            name: '一部',
            number: '0',
            director: '',
            range: '',
            children: [
                {
                    id: 201,
                    flag: true,
                    checked: false,
                    name: '二部',
                    number: '0',
                    director: '',
                    range: '',
                },
            ],
        },
    ];
    private organizeUploadTab: any = [{
        name: '研究院',
        flag: '否',
        limit: '否',
    }];
    private addDep() {
        this.infoTitle = '添加部门';
        this.infoFlag = true;
    }
    private batchAdd() {
        this.notUpload = true;
        this.activePage = 2;
        this.contentTitle = '批量添加部门';
    }
    // 接收子页面传回来的值
    private propGoBlack() {
        this.activePage = 1;
        this.contentTitle = "组织机构";
    }
    private uploadFile() {
        this.notUpload = false;
    }
    private showDeclare() {
        this.isShowDeclare = !this.isShowDeclare;
    }
    private infoCloseMsg(num: number) {
        this.infoFlag = false;
    }
    private editDep(row: any) {
        this.depFlag = true;
        this.depData = row;
        this.depTitle = '编辑部门';
    }
    private depCloseMsg(num: number) {
        this.depFlag = false;
    }
    private deleteClick() {
        this.selectDep = [];
        this.organizeTab.forEach( (item: any) => {
            if (item.checked) {
                this.selectDep.push(item.id);
            }
        });
        console.log(this.selectDep);
        if (this.selectDep.length === 0) {
            this.emptyFlag = true;
            this.contentTxt = "请先选择需要删除的部门！";
        } else {
            const row = {};
            this.tabToolData(row, "删除", "删除部门", "删除选中的部门及相关联的子部门也会一并删除，是否继续删除？");
        }
    }
    private tabToolData(rowData: any, btnText: string, title: string, content: string) {
        this.tabFlag = true;
        this.rowData = rowData;
        this.btnText = btnText;
        this.title = title;
        this.content = content;
    }
    private tabCloseMsg(num: number) {
        this.tabFlag = false;
    }
    private checkboxT(row: any, index: number) {
      if (row.id === 1) {
        return false;
      } else {
        return true;
      }
    }
    private emptyCloseMsg(num: number) {
        this.emptyFlag = false;
    }
}
