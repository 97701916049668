
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
    name: "SuccessInfo",
})
export default class SuccessInfo extends Vue {
    @Prop(Object) private resData!: object;
    private companyName: string = "test";
    private businessLicenseTab: any = {};
    private registrationStr = '';
    private changeStatus: number = 0;
    @Watch('resData', {immediate: true, deep: true}) // 监听父组件传过来的值
    private resDataFun(value: number) {
        this.businessLicenseTab = JSON.parse(JSON.stringify(value)).businessLicense;
        const registrationNum = String(this.businessLicenseTab.registrationNumber);
        const registrationLen = registrationNum.length;
        this.registrationStr = registrationNum.substring(0, 3) + '***'
                                + registrationNum.substring(registrationLen - 3, registrationLen);
        this.changeStatus = JSON.parse(JSON.stringify(value)).changeStatus;
    }
}
