
import '@/assets/css/iframeStyle.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import TrainList from "./component/TrainList.vue";
import InviteStaff from "../../home/component/InviteStaffDialog.vue";

@Component({
    name: "Staff",
    components: {
        TrainList,
        InviteStaff,
    },
})
export default class Staff extends Vue {
    // InviteStaff
    private inviteStaffFlag: boolean = false;
    // end
    private baseUrlIframe = process.env.VUE_APP_URL;
    private contentTitle: string = "员工管理";
    private activePage: number = 1;
    private propBack(value: boolean) {
        this.activePage = 1;
    }
    private clickInvite() {
        this.inviteStaffFlag = true;
    }
    private propInviteStaff() {
        this.inviteStaffFlag = false;
    }
}
