
import { Component, Vue, Prop, Watch, Emit  } from 'vue-property-decorator';

@Component({
    name: "InviteStaff",
})
export default class InviteStaff extends Vue {
    private inviteTab: any = {};
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    private showDialog: boolean = false; // 弹窗显示与关闭
    @Watch('exitFlag') // 监听父组件传过来的值
    private changeFlagFun(value: boolean) {
        this.showDialog = value;
        if (value) {
            this.getInvitation();
        }
    }
    // 给父组件传值
    @Emit('closeMsg')
    private closeTodo(num: number): number {
        this.showDialog = false;
        return num;
    }
    //  确定按钮
    private closeDialog(num: number) {
        this.closeTodo(num); // 给父组件传值
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeTodo(0); // 给父组件传值
    }
    // 得到邀请数据
    private getInvitation() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/company/info/invitation')
        .then((res: any) => {
            this.inviteTab = res;
        });
    }
    // 更新邀请码
    private updateInvitationCode() {
        this.$httpService.putData({}, '/apiProxy/api/frontend/company/info/invitation/code')
        .then((res: any) => {
            this.$message.success('更新邀请码成功');
            this.inviteTab.code = res;
        });
    }
    private copyUrl(copyValue: any, type: number) {
        const domUrl = document.createElement("input");
        const expirationTime = this.inviteTab.expirationTime || '-';
        const companyName = this.$store.state.userInfo.nickname || '-';
        if (type === 1) {
            domUrl.value = copyValue + `\n 【${companyName}】邀请您加入，链接有效期至 ${expirationTime}`;
        } else {
            domUrl.value = copyValue;
        }
        domUrl.id = "createDom";
        document.body.appendChild(domUrl);
        domUrl.select(); // 选择对象
        document.execCommand("Copy"); // 执行浏览器复制命令
        const createDom: any = document.getElementById("createDom");
        createDom.parentNode.removeChild(createDom);
        if (type === 1) {
            this.$message.success('复制链接成功');
        } else if (type === 2) {
            this.$message.success('复制邀请码成功');
        } else {
            this.$message.success('复制成功');
        }
    }
}
