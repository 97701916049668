
import '@/assets/css/iframeStyle.scss';
import { Component, Vue} from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import CardInfo from "./component/CardInfo.vue";
import InvoiceInfo from "./component/InvoiceInfo.vue";
import BaseInfo from "./component/BaseInfo.vue";
import Certification from "../../certification/index.vue";

@Component({
    name: "Material",
    components: {
        ContentTitle,
        CardInfo,
        InvoiceInfo,
        BaseInfo,
        Certification,
    },
})
export default class Material extends Vue {
    private baseUrlIfram = process.env.VUE_APP_URL;
    private contentTitle: string = "企业资料";
    private activeName: string = 'first';
    private initInfo: any = {
        base: {},
        bank: {},
        invoice: {},
    };
    // 配置页面
    private activePage: string = '1'; // 1：主页面；2：认证修改页面
    private created() {
        this.getInfo();
    }
    private getInfo() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/company/info').then((res: any) => {
            this.initInfo = res;
            if (this.$route.fullPath.indexOf('active=') > -1) {
                const active = this.$route.query.active + '';
                this.activeName = active === '2' ? 'second' : 'first';
            }
        });
    }
    // 自组件保存成功之后刷新页面
    private reload() {
        this.getInfo();
    }
    private receiveCertification(num: any) {
        this.activePage = '1';
    }
}
